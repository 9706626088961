import './App.css';

import githubIcon from './images/github-mark.svg';
import linkedinIcon from './images/In-Blue-96.png';
import homelabImage from './images/homelab2.png';
import egaImage from './images/egabg.png';

import { useEffect } from 'react';
import ReactGA from 'react-ga';


function App() {
  useEffect(() => {
    ReactGA.initialize('G-D4X7JTV091');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div
        className="App bg-no-repeat bg-left-bottom" 
        style={{ 
          backgroundImage: `url(${egaImage})`,
          backgroundSize: '150px 150px',
          backgroundOpacity: '0.1',
        }}>
      <header className="bg-indigo-800 text-white body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <nav className="flex lg:w-2/3 flex-wrap items-center text-base md:ml-auto">
            <a href="#about" className="mr-5 hover:text-gray-400">About</a>
            <a href="#contact" className="mr-5 hover:text-gray-400">Contact</a>
            <a href="#homelab" className="mr-5 hover:text-gray-400">Homelab</a>
          </nav>
          <a href="https://wrbunce.com" className="flex order-first lg:order-none lg:w-1/3 title-font font-medium items-center text-gray-900 lg:items-center lg:justify-center">
            <span className="ml-3 text-xl text-white">William Bunce</span>
          </a>
        </div>
      </header>

      <section id="about" className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          {/* <img className="lg:w-1/6 md:w-1/4 w-1/3 mb-10 object-cover object-center rounded" src="path_to_your_image.jpg" alt="Your image"/> */}
          <div className="text-center lg:w-2/3 w-full">
          <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">WHO AM I?</h2>
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Hi, I'm Bill</h1>
            <p className="mb-6 leading-relaxed">
            I'm an accomplished Network and Satellite Systems Engineer with over eighteen years of experience in mission-critical network operations, troubleshooting, and maintenance, complemented by a strong background in end-to-end data and transmission network planning and design. 
            </p>
            <p className="mb-6 leading-relaxed">
            My technical expertise is further enhanced by over ten years in programming and scripting, with proficiency in Python, PHP, Shell scripting and a moderate knowledge base of React and Next.js.
            </p>
            <p className=" leading-relaxed">
            More recently, I have honed my leadership skills, overseeing diverse teams and managing large-scale projects across multiple states. Adept at driving both technical excellence and team development, I excel in high-pressure environments requiring strategic vision and meticulous execution.
            </p>
          </div>
        </div>
      </section>

      <section id="contact" className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-2">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">REACH OUT TO ME</h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">Contact</h1>
          </div>
          <div className="container px-5 py-6 mx-auto flex justify-center">
            <a href="https://github.com/ds2600" target="_blank" rel="noopener noreferrer" className="w-24 h-24 md:w-32 md:h-32 lg:w-24 lg:h-24 xl:w-32 xl:h-32 mx-8 flex items-center justify-center">
              <img src={githubIcon} alt="GitHub" className="max-w-full max-h-full" />
            </a>
            <a href="https://linkedin.com/in/williambunce" target="_blank" rel="noopener noreferrer" className="w-24 h-24 md:w-32 md:h-32 lg:w-24 lg:h-24 xl:w-32 xl:h-32 mx-8 flex items-center justify-center">
              <img src={linkedinIcon} alt="LinkedIn" className="max-w-full max-h-full" />
            </a>
          </div>
        </div>
      </section>
      <section id="homelab" className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <div className="flex flex-col text-center w-full mb-8">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">IT'S JUST A "HOBBY"</h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">Homelab</h1>
          </div>
          <a href="https://wrbuncecom.files.wordpress.com/2023/04/homelab2.png" target="_blank" rel="noopener noreferrer">
            <img 
              className="h-96 md:h-96 object-cover cursor-pointer" 
              src={homelabImage} 
              alt="Homelab Environment"
            />
          </a>
        </div>
      </section>
    </div>
  );
}

export default App;
